import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getPodcastUrl } from 'state/Podcast/helpers';
import { Helmet } from 'react-helmet';
import { parse } from 'qs';
import { link as playerLink, meta as playerMeta } from 'heads/player';
import { useCallback } from 'react';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';

type Props = {
  description: string;
  imgUrl: string;
  location: Location;
  pathname: string;
  seedId: number;
  siteUrl: string;
  slug: string;
  title: string;
};

function PodcastEpisodesHead({
  description,
  imgUrl,
  location,
  seedId,
  slug,
  siteUrl,
  title,
}: Props) {
  const translate = useTranslate();

  const getDeeplink = useCallback(() => {
    const query = parse(location.search.slice(1));
    if (query.follow === 'true') {
      return `follow/podcast/${seedId}`;
    }
    return `play/custom/talk/show/${seedId}`;
  }, [location, seedId]);

  const pagePath = getPodcastUrl(seedId, slug);
  const { height, fixedWidth: width }: WidgetDimensions =
    WIDGET_DIMENSIONS.PODCAST_PROFILE ?? {};
  const podcastDescription = description?.replace(/\n+/g, ' ');

  return (
    <>
      <GenericHead
        deeplink={`goto/talk/show/${seedId}`}
        description={podcastDescription}
        facebookImage={`${imgUrl}?ops=contain(1200,630)`}
        image={imgUrl}
        legacyDeeplink={getDeeplink()}
        ogType="video"
        title={title}
      />
      <Helmet
        link={playerLink({ pagePath, siteUrl, title })}
        meta={[
          {
            content: title,
            itemProp: 'name',
          },
          {
            content: translate(
              '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart',
              {
                title,
              },
            ),
            name: 'keywords',
          },
          ...playerMeta({ pagePath, siteUrl, height, width }),
        ]}
      />
    </>
  );
}

export default PodcastEpisodesHead;
